<template>
  <v-app id="inspire" translate="no" class="notranslate">




    <!-- <br/> <br/> <center> show_boleto:{{ show_boleto }}</center> <br/> <br/> -->


    <!-- MOSTRA CHAT -->
    <div v-if="(app_bar)&&(2>1)">
      <v-row>
        <v-col>
        <ChatVue 
        v-if="(esta_logado)"
        :aberto = 'show_chat'
        @muda_aberto="muda_aberto"
        @muda_count="muda_count"
        />
        </v-col>
      </v-row>
    </div>


    <!-- MOSTRA LEMBRETES -->
    <div v-if="(app_bar) &&(2>1)">
      <v-row>
        <v-col>
        <LembreteVue 
        v-if="(esta_logado)"
        :aberto = 'show_lembretes'
        pro_tabela = 'ALERTA'
        pro_titulo = 'Lembretes'
        :pro_obj_1 = null
        :pro_obj_items = null
        :show_menu = 'false'
        :pro_pode_alterar = 'true'
        @muda_aberto_lembretes="muda_aberto_lembretes"
        @muda_count_lembretes="muda_count_lembretes"
        />
        </v-col>
      </v-row>
    </div>
    <!-- deixei aqui em cima pois em baixo gerava uma borda deslizando a tela na vertical -->


    <v-navigation-drawer
      v-model="drawer"
      :mini-variant = "true"
      :app ="true"
      :permanent="false"
      color = "grey lighten-4"
      disable-resize-watcher
      class="no-print"
    >
    <!-- :expand-on-hover="$vuetify.breakpoint.lgAndUp"   -->
    
    <!-- stateless -->
    <!-- v-model="drawer" -->
    
 <!-- <v-img
  :src="src_img"
></v-img>    -->

      <v-list-item class="px-2"
      >
      <!-- <v-img
          :src="src_img"
          @click="navega()"
          link
        ></v-img>   -->
        
        
        <v-card
          height="40"
          width="60"
          class="blue white--text text-center "
          elevation="0"
          @click="navega()"

        >
          <div class="pa-0 mt-2">
            <v-row dense class="grey pa-0 ma-0">
              <v-col class="pa-0 px-1 blue">
                <span class="font_dsi">DSi</span>
              </v-col>

              <!-- <v-col class="pa-0 px-1 white">
                <span class="font-weight-regular pa-0 ma-0 text-caption grey--text">MedClinic</span>
              </v-col> -->
            </v-row>
          </div>
        </v-card>
        
        <!-- <v-list-item-avatar>
          <v-img :src="src_img"></v-img>
        </v-list-item-avatar> -->

            <!-- <v-list-item-avatar>
              <v-img 
              :src="src_img"
              />
            </v-list-item-avatar> -->


        <v-list-item-content>

                <!-- <img
                  ref="print_logo"
                  :src="src_img"
                  style="max-width: auto; height: 100px"
                /> -->



          <!-- <v-list-item-title class="text-h6">
            Clinica
          </v-list-item-title>
          <v-list-item-subtitle>
            Sistemas DSi
          </v-list-item-subtitle> -->
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>




      <v-list
        dense
        nav
      >
        <v-list-item
          v-for= "item in items"
          :key= "item.title"
          :to = "item.to"
          link
          active-class="blue--text"
        >
        <!-- :target="item.target" -->
        
          <v-list-item-icon
          v-if="isMobile"
          >
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>


          <v-tooltip bottom
          v-else
          >
            <template v-slot:activator="{ on, attrs }">
                  <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.color"
                >
                <!-- :color="item.color" -->
                  {{ item.icon }}
                </v-icon>
            </template>
            <span> {{ item.title }}</span>
          </v-tooltip>
          

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      <v-divider class="mx-0"></v-divider>
      &nbsp
      <p v-if="eu==''" class="caption"  style="font-size: 0.65rem !important;"> {{ versao }} </p> 
      <p v-else class="caption red--text"  style="font-size: 0.65rem !important;"> {{ versao }}</p> 
  
      </v-list>
    </v-navigation-drawer>

      <v-app-bar 
      v-model="app_bar"
      :app = "true"
      color="blue"
      height="45"
      :hidden="esconde"

      >
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <!-- {{ verificou_DSi }} -->

        <v-card
          height=""
          class="grey white--text text-center ml-0  pa-0"
          elevation="4"
          v-if="(!isMobile)"
        >
          <div class="pa-0">
            <v-row dense class="grey lighten-3 pa-0 ma-0">
              <v-col class="pa-0 px-1 blue lighten-0">
                <span class="font-weight-regular pa-0 ml-1 text-h5">DSi</span>
              </v-col>

              <v-col class="pa-0 px-1 grey lighten-3 mt-1">
                <span class="font-weight-regular pa-0 ma-0 text-h7 black--text">MedClinic</span>
              </v-col>
            </v-row>
          </div>
        </v-card>
    
        <v-card class="ml-2" v-if="(!isMobile)">
          <!-- EMPURRA -->
        </v-card>

        <v-card
          height=""
          elevation="0"
          class="blue lighten-0 white--text text-center   pa-1 px-2"
        >
          <h5 v-if="isMobile" class="font-weight-regular" >{{ (empresa) }}</h5>
          <h3 v-else class="font-weight-regular">{{ (empresa) }}</h3>

        </v-card>


       <v-spacer></v-spacer>


      <!-- {{ drawer }} -->
      <!-- <v-toolbar-title
      style="font-size:16px; color: #263238"
      >
       {{ user_name }}     
      </v-toolbar-title> -->




      <v-badge
      :content="dias_dif_aqui"
        :value="dias_dif_aqui"
        color="red"
        overlap
        :dot="(isMobile)"
        :class="[(dias_dif_aqui == 0) ? 'orange' : (dias_dif_aqui < 0) ? 'red' : 'primary darken-3', 'pl-1 mr-4 mt-0 white--text ' ]"
        v-if="(show_boleto) && (2>1)" 
        @click="DSi_PAGAR_PIX()"
      >
      <!-- :class="[(boleto_atrasado) ? 'blue darken-3': 'blue darken-3', 'pl-1 mr-4 mt-0 white--text ' ]" -->
      <!-- white black--text pl-2 -->


      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <a class="white--text" @click="DSi_PAGAR_PIX()"
              v-bind="attrs"
              v-on="on"
          >
          <span style=" text-align: right; font-size: 10px;" class="white--text pa-0 mt-2 ml-0"> 
            <span v-text="(dias_dif_aqui == 0) ? 'hoje':'dia '+ dia_vence_aqui">  </span>
          </span>  
          BOLETO 
        </a>

        </template>
        <span> Vencimento dia {{ dia_vence_aqui }}  </span>
      </v-tooltip>


        <v-icon 
          color="white" 
          :size="(isMobile) ? 25: 30"
          class="mr-1"
          @click="DSi_PAGAR_PIX()"
        >
           mdi-barcode
        </v-icon>
         <!-- BOLETO⠀ -->
         <!-- PIX DSI⠀ -->
      </v-badge>



      <!-- ABRE CHAT -->
      <v-badge
        :content="count_chat"
        :value="count_chat"
        color="red"
        overlap
        :dot="(isMobile)"
        class="mr-3"
        @click="f_show_chat()"
      >
      
        <v-icon 
          color="white" 
          @click="f_show_chat()"
          :size="(isMobile) ? 25: 28"
        >
          mdi-chat-processing
        </v-icon>
      </v-badge>



      <!-- ABRE LEMBRETES -->
      <v-badge
        :content="count_lembrete"
        :value="(count_lembrete>0) ? count_lembrete : ''"
        color="red"
        overlap
        :dot="(isMobile)"
        class="mr-5"
        @click="f_show_lembretes()"
      >
      
        <v-icon 
          color="white" 
          @click="f_show_lembretes()"
          :size="(isMobile) ? 25: 27"
        >
        mdi-bell
        </v-icon>
      </v-badge>


      <!-- ABRE LEMBRETES -->
      <!-- <v-btn icon color="white" v-if="(2>1)" class="mr-2"
        @click="f_show_lembretes()"
        >
        <v-icon 
        :size="(isMobile) ? 25: 27"
        >
        mdi-bell
      </v-icon>
      </v-btn> -->


      <v-menu
        bottom
        right
        min-width="180"
      >
        <template v-slot:activator="{ on, attrs }">



          <v-icon dark 
            v-bind="attrs"
            v-on="on"
            small
          >
            <!-- mdi-dots-vertical -->
            mdi-chevron-down 
          </v-icon> 

          <v-avatar
            :size="(isMobile) ? 25: 30"
            color="grey lighten-3"
            v-bind="attrs"
            v-on="on"
          >
            <span class="primary--text font-weight-bold"> {{user_name_abreviado}} </span>
          </v-avatar>




        </template>

        <v-list dense>

          <v-subheader class="grey lighten-3">
            <v-icon> mdi-account-circle</v-icon>
            <h4 class="ml-1"> {{ user_name }} </h4>
          </v-subheader>
          
          <v-divider class="mx-0"></v-divider>


          <v-list-item
            v-for="(item, i) in items_avatar"
            :key="i"
            @click="acao_menu_avatar(item.click)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-icon>
            <v-icon :color=item.icon_color> {{ item.icon }} </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu>

      
      <!-- <v-btn
       text
       color="white"
       @click="sair">  
        Sair 
        <v-icon>mdi-power-standby</v-icon>
      </v-btn> -->

      </v-app-bar>

    <v-main>
      <router-view 
        @executar="changeDrawer" 
        @executar2="ler_session"
        @carregar_componentes="carregar_componentes"
      ></router-view>
      <!-- @mostrar_app_bar="mostrar_app_bar" -->
    </v-main>

  </v-app>


</template>

<script>

import axios from "axios";
import DSirotinas from './assets/js/DSi_rotinas'//chamo funcao do arquivo
import { define_data_hora, diferenca_datas } from './assets/js/DSi_basico'
  // import ChatVue from './views/chat.vue';
  // import LembreteVue from './views/Lembretes.vue';

  DSirotinas.url_app()//estava no login.. passei pra cá



  export default {

    components:{
      // ChatVue,
      // LembreteVue

      // IMPORTO COMPONENTES AQUI NAO PRECISA DECLARAR EM CIMA.. E NAO GERA ERRO DO URL e so chamo apos logado
      ChatVue:     () => import("./views/chat.vue"),
      LembreteVue: () => import("./views/Lembretes.vue")

      // https://stackoverflow.com/questions/55581345/dynamic-importing-components-based-on-route
    },
  
    data: () => ({ 
      
      versao:'v 2.3.82',
      esconde: true,
      drawer: false,
      app_bar:false,

      verificou_DSi: false,


      RASTREAR: false, //ativo pra ver as etapas da criação do app

      show_boleto: false,
      boleto_atrasado: false,

      dia_vence_aqui:'',
      dias_dif_aqui:0,

      show_chat: false,
      show_lembretes: false,

      count_chat:0,
      count_lembrete:0,

      isMobile:false,

      esta_logado: false,

      user_name:'',
      user_name_abreviado:'',
      src_img:'',
      empresa:'',
      eu:'',

      items: [
          { title: 'Agenda', icon: 'mdi-calendar-clock ', color:'', to: '/agenda', target:'self' },
          // { title: 'Agenda2', icon: 'mdi-calendar-multiple-check  ', color:'', to: '/agenda2', target:'self' },
          { title: 'Cadastro', icon: 'mdi-account', color:'', to:'/paciente', target:'_blank' },
          // { title: 'Tabelas', icon: 'mdi-cogs', color:'', to:'/tabelas', target:'_blank' },
          { title: 'Tabelas', icon: 'mdi-view-list', color:'', to:'/tabelas', target:'_blank' },
          // { title: 'Tabelas', icon: 'mdi-cogs', color:'', to:'/tabelas', target:'_blank' },
          { title: 'Campanhas', icon: 'mdi-whatsapp ', color:'', to:'/WhatsLab', target:'_blank' },
          // { title: 'Campanhas', icon: 'mdi-message-bulleted ', color:'', to:'/WhatsLab', target:'_blank' },
          // { title: 'Configurações', icon: 'mdi-cogs', color:'', to: '/configuracoes' },
          // { title: 'Profissionais', icon: 'mdi-account-school-outline', to:'/profissionais', target:'_blank' },
          // { title: 'Pacientes', icon: 'mdi-account ', to:'/print' },
        ], 

      items_avatar:[],  

      painel_fixo:false,  

      }),


   created(){
    this.rastreia('App.vue - created start')
    this.logado()
    this.isMobile = window.innerWidth < 600
    this.setVersao()
    this.monta_menus_avatar()
    this.rastreia('App.vue - created end')


  
    this.$on('voted', function(button) {
      this.votes++
    })
  
   },

    
   watch: {


     
   },


    methods:{


      mostra_campanhas(){

        // ja deixei como padrao ativado
        // console.log('item:', this.items);
        // this.items.push({ title: 'Campanhas', icon: 'mdi-whatsapp ', color:'', to:'/WhatsLab', target:'_blank' } )
      },

      async DSi_PAGAR_PIX(){

        console.log('DSi_PAGAR_PIX:');
        
        let IDE =  sessionStorage['emp_ide'];

        let link = ''
        let teste = sessionStorage['url'];
        if (teste == '/api/'){
          link = `http://localhost/vue_cfc/public/clientes/?token=pi45i${IDE}`//teste
        }
        else{
          link = `https://dsi.app.br/f/c/clientes/?token=pi45i${IDE}`//produção
        }

        const res = await axios.get(link);
        // console.log(res);
        console.log(res.data);

        //isso daqui é lido la em App.vue
        sessionStorage['boleto_mostrar']  = 'S'
        sessionStorage['boleto_atrasado'] = '?'

        if (res.data.id_pix){//gerou ok
          window.open(res.data.pagina_retorno, '_blank');


          //caso clique em pagar.. gera um timer para ver se pagou ou nao
          setTimeout(function(){
            this.DSi_GERAR_PARCELA()  
            console.log('TIMER: DSi_GERAR_PARCELA');
          }.bind(this), 120000);//atualiza em 2 minutos

        }

      },


      async DSi_GERAR_PARCELA(){

        define_data_hora(this)//passo o this para que la ele defina as variaveis daqui

        
        let IDE =  sessionStorage['emp_ide'];

        let link = ''
        let teste = sessionStorage['url'];
        if (teste == '/api/'){
          link = `http://localhost/vue_cfc/public/clientes/?token=ci45i${IDE}`//teste
        }
        else{
          link = `https://dsi.app.br/f/c/clientes/?token=ci45i${IDE}`//produção
        }

        const res = await axios.get(link);
        
        console.log('DSi_GERAR_PARCELA:', res.data);

          

        

        await fazer(this, res.data)

          async function fazer(that, dados){

            // console.log('fazer:',dados);


            //pega dados das assinaturas..procura modulo zap
            let assinaturas = ''
            let zapzap      = null
            if (dados.dados_assinatura){
              console.log('assinaturas:', dados.dados_assinatura);
              assinaturas = dados.dados_assinatura

              zapzap = assinaturas.filter(x => x.SERVICO === 'MODULO WHATSAPP'); //clonando array copiar array
              // zapzap = zapzap[0]
              console.log('zapzap:', zapzap);
              
              if ((zapzap) && (zapzap.length>0)){
                sessionStorage['array_whats'] = JSON.stringify(zapzap)
              }
              else{
                // sessionStorage['array_whats'] = JSON.stringify(false)
                sessionStorage['array_whats'] = ''
              }


              // console.log('zapzap.SITUACAO:', zapzap.SITUACAO);
              if ((zapzap)&&(zapzap.SITUACAO !='CANCELADO')){
                  that.mostra_campanhas()
              }
            }


            sessionStorage['config_whats']  =  ''

            // if (zapzap){
            //   // sessionStorage['config_whats']  =  JSON.stringify(zapzap)
            //   // sessionStorage['config_whats']  =  ''
            // }
            // else{
            //   // sessionStorage['config_whats']  =  JSON.stringify(false)
            //   sessionStorage['config_whats']  =  ''
            // }


          }



        let dia_vence = 0
        if (res.data.dados_parcela?.DATA_VENCIMENTO ){//pega pelo vencimento
          let aux = res.data.dados_parcela?.DATA_VENCIMENTO
          aux = aux.substring(8,10)
          dia_vence = parseInt(aux)
        }
        else{
          dia_vence = res.data.dados_cliente?.DIA_VENCIMENTO //senao pega pela dava vencimento salvo no cadastro do cliente
        }


        this.dia_vence_aqui = dia_vence
        
        let pago      = (res.data.dados_parcela?.PAGO == 'S') ? 'S' : 'N'
        let dia_hoje  = this.dia_hoje
        let dias_dif = 0
        let mostrar = 'N'
        let atrasado = res.data.atrasado

        // console.log('pago:'+pago);

        if (pago == 'S'){
            mostrar = 'N'
        }
        else {


          dias_dif = parseInt(dia_vence) - parseInt(dia_hoje)
          this.dias_dif_aqui = await diferenca_datas(this.today, res.data.dados_parcela?.DATA_VENCIMENTO)

          if (atrasado == 'S'){
              mostrar = 'S'
          }
          else{
              // console.log('dia_vence: '+dia_vence+' hoje: '+this.dia_hoje + ' dias_dif: '+dias_dif)
            
              if (dias_dif <=7){
                console.log('PODE MOSTRAR');
                mostrar = 'S'
              }
          }
        }

        // console.log('mostrar:'+ mostrar);
        // console.log('atrasado:'+ atrasado);
        
        // SOMENTE SE FOR SUPER USER
        if ( sessionStorage['r0s9i3khepus_is'] == 'S'){
          this.show_boleto     = (mostrar=='S') ? true : false
          this.boleto_atrasado = (atrasado == 'S') ? true : false
        }
        // res.data.bloquear

      },


      async refresh_app(){
        console.log('Refresh App:');
        if (!this.verificou_DSi){
          this.DSi_GERAR_PARCELA()
          this.verificou_DSi = true
        }
      },


      mostrar_app_bar(valor){
        this.app_bar = valor
      },

      carregar_componentes(valor){
        this.esta_logado = valor
      },

      rastreia(tag){
        if (this.RASTREAR){
           console.log(tag);
        }
     },

      f_show_lembretes(){
        this.show_lembretes =!this.show_lembretes

        // setTimeout(function(){
        //   this.show_chat = false  
        // }.bind(this), 200);
      },

      f_show_chat(){
        this.show_chat =!this.show_chat

        // setTimeout(function(){
        //   this.show_chat = false  
        // }.bind(this), 200);
      },


      monta_menus_avatar(){
        
        this.items_avatar.push( { title: 'Sair', icon: 'mdi-power-standby', icon_color: "blue", click: 'sair' } )
      },

      acao_menu_avatar(acao){

        console.log(acao);
        if (acao=='sair'){
           this.sair()
        }
      },

      abrevia_nomes(entra,coringa){
        let partes = entra.split(coringa);

        // console.log(partes);

        let A = ''
        let B = ''

        if (partes[1][0]){
          A = partes[1][0]
          B = partes[1][1]
        }

        if (partes.length>2){
          B = partes[2][0]
        }
        
        // console.log('A:'+A +'  B:'+B);
        let r = ''

        if (A){ r  = A}
        if (B){ r += B}

        return r
      },


      muda_aberto_lembretes(valor){
        this.show_lembretes = valor
      },

      muda_count_lembretes(valor){
        this.count_lembrete = valor 

        //aproveito essa função que executa somente apos logado
        this.refresh_app()
      },

      muda_aberto(valor){
        this.show_chat = valor
      },

      muda_count(valor){
        this.count_chat = valor 

        if (valor>0){
          document.title = `(${valor}) `+'DSi - '+ sessionStorage['LSEmpresa'] 
        }
        else{
          document.title = 'DSi - '+ sessionStorage['LSEmpresa'] 
        }
        

        //aproveito essa função que executa somente apos logado
        this.refresh_app()
      },

      setVersao(){
          localStorage['v'] = this.versao
      },

      navega(){
        let href="https://sistemasdsi.com.br/"
        window.open(href,'_blank');
      },

      logado(){

          this.rastreia('App.vue - logado start')

          let logado =  this.read_sessionStorage('buffer')
          if (logado !=='S'){
            // console.log('logado');
            this.esta_logado = false
            this.ops()

          }
          else{
            this.esta_logado = true

            this.eu = this.read_sessionStorage('84hfjdyruxy1_is')
            this.eu = DSirotinas.cripto(this.eu)
            this.eu = this.eu.trim()
            if (this.eu!=='A'){
                this.eu = ''//limpa
                this.ops()
            }
          }

          this.rastreia('App.vue - logado end')
      },

      ops(){
        if (sessionStorage['url']!=='/api/'){
            DSirotinas.devtools();//F12 
        }
      },

      oi(){
        console.log(DSirotinas.cripto('cleberson'));
      },

      sair(){

         this.esta_logado = false
         sessionStorage['logout'] = 'S'//manda fazer logout no / = login

         localStorage['reload_page'] = 'S' //mando dar um F5 la
         localStorage['enviou_MED_PRINT'] = JSON.stringify(false)

         this.$router.push('/')//redireciona para a pagina login
      } ,

      changeDrawer (newDrawer) {


        this.app_bar = newDrawer

        if (this.$vuetify.breakpoint.mdAndDown){
          this.drawer = false
          // alert('md and down')
        }else{
          this.drawer = newDrawer
        }

        

        if (newDrawer == true){
          this.esconde = false
        }
        else{
          this.esconde = true
        }
      },

      ler_session(){//estou chamando isso no logado() da agenda.vue via emit
        this.empresa = sessionStorage['LSEmpresa']
        
        this.user_name = this.read_sessionStorage('84hfjdyruxy1_is')
        this.user_name = DSirotinas.cripto(this.user_name) 

        // this.user_name_abreviado = this.user_name

        this.user_name_abreviado = this.abrevia_nomes(this.user_name,' ')

        this.src_img = "https://dsi.app.br/dsi.png"
        // this.src_img = "http://localhost/medclinic.png"
      },

     read_sessionStorage($name,$to){
        if (sessionStorage[$name]) {
           return sessionStorage[$name]
        } 
     },


      meuTimer(){

        setTimeout(function(){
        

        }.bind(this), 2000);//usar esse .bind(this) para chamar funcoes com o setTimeout
      },
    }
  }
</script>


<style scoped>
.count_item {
  position: relative !important;
  top: -7px;
  left: -7px;
  font-size: 80%;
  vertical-align: super;
  color: white;
}

.v-badge__badge  {
    font-size: 10px !important;
    height: 17px !important;
    min-width: 17px !important;
}


.v-badge--dot .v-badge__badge {
    border-radius: 4.5px !important;
    height: 9px !important;
    min-width: 0 !important;
    padding: 0 !important;
    width: 9px !important;
}

.font_dsi{
  font-size: 18px;
}

.font_medclinic{
  font-size: 5px;
}


@media print {
    .no-print {
      display: none !important;
    }

    .v-app,
    .v-footer,
    .v-navigation-drawer,
    .v-card-actions,
    .v-toolbar {
      display: none !important;
    }

 }

</style>